import * as React from 'react'
import { StripeProvider } from 'react-stripe-elements'
import { LoadStripe } from './helpers'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/configStore'
import Routes from './Routes'
import './global.scss'
import { Provider } from 'react-redux'

export default function App() {
  return (
    <LoadStripe uniqueId="stripe-js" script="https://js.stripe.com/v3/" loader="Loading...">
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY as string}>
        <Provider store={store as any}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </StripeProvider>
    </LoadStripe>
  )
}

import { connect } from 'react-redux'
import { NonProfitKeys, setNonProfit, clearNonProfit } from '../reducers/NonProfitDonation'
import NonProfitDonation from '../../pages/nonprofit/donation/index'
import PaymentInfo from '../../pages/nonprofit/donation/payment-info/index'
import DonationSummary from '../../pages/nonprofit/donation/donation-summary/index'
import NonProfitThanks from '../../pages/nonprofit/donation/thanks/index'
import WithoutAccount from '../../pages/nonprofit/without-account/index'
import CampaignLanding from '../../pages/nonprofit/auth/campaign/index'

function MSTPNonProfitDonation(state: any) {
  return {
    ...state.NonProfitDonation,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setNonProfit: (key: NonProfitKeys, val: number | string | boolean | object) => dispatch(setNonProfit(key, val)),
    clearNonProfit: () => dispatch(clearNonProfit()),
  }
}

export const _NonProfitCampaign = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(CampaignLanding)

export const _NonProfitDonation = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(NonProfitDonation)

export const _PaymentInfo = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(PaymentInfo)

export const _DonationSummary = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(DonationSummary)

export const _NonProfitThanks = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(NonProfitThanks)


export const _WithoutAccount = connect(
  MSTPNonProfitDonation,
  mapDispatchToProps,
)(WithoutAccount)

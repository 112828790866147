import * as React from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import BaseService from '../../services/base.service'
import { Subscription } from '../../services/subscription.service'
import { getInterval } from '../../helpers'

const baseService = new BaseService()

interface Props extends RouteComponentProps<any> {
  app_link?: string
}

interface State {
  subscriptions: Subscription[]
}

export default class Subscriptions extends React.Component<Props, State> {
  state = {
    subscriptions: [],
  }

  getSubscriptions = async () => {
    const res = await baseService.getJSON('donation/subscription')
    if (!res.error) {
      this.setState({
        subscriptions: res.subscriptions || [],
      })
    }
  }

  async componentDidMount() {
    await this.getSubscriptions()
  }

  render() {
    return (
      <main className="screen subscription-page">
        <header className="header">
          <nav className="main-menu">
            <span className="home" onClick={() => window.history.back()}>
              <img src="/assets/images/home-icon.png" alt="home-icon" />
            </span>
            <img src="/assets/images/logo.png" alt="logo" />
          </nav>
        </header>

        <section className="sub-header">
          <h1>Monthly Donation</h1>
          <p>Subscriptions</p>
        </section>

        <section className="subscriptions">
          {this.state.subscriptions.length > 0 &&
            this.state.subscriptions.map((subscription: Subscription, index: number) => (
              <Link className="donate" to={`/subscriptions/${subscription.id}`} key={index}>
                <div className={`list ${subscription.paused ? 'paused' : ''}`}>
                  <div className="org">
                    <div className="name">{subscription.organizationName}</div>
                    <div className="interval">{getInterval(subscription)}</div>
                  </div>

                  <div className="amount">
                    <span>${(subscription.amount / 100).toFixed(2)}</span>
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 7.41 12">
                        <path
                          d="M15.41,16.58,10.83,12l4.58-4.59L14,6,8,12l6,6Z"
                          style={{ fill: subscription.paused ? '#ff7547' : '#3D3D3D' }}
                          transform="translate(15 18) rotate(180)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </section>
      </main>
    )
  }
}

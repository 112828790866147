import * as React from 'react'
import styled from 'styled-components'
import { PAYMENT_METHODS } from '../../../constants'
import { Methods } from '../../../interfaces'
import PlaidLink from 'react-plaid-link'
import './style.scss'

const Card = styled<any>('div')`
  border: 0.5px solid ${(props: any) => (props.disabled ? '#707070' : '#3d3d3d8c')};
  background-color: ${(props: any) => (props.disabled ? '#EEEEEE' : 'white')};
  path {
    fill: ${(props: any) => (props.disabled ? '#AAAAAA' : '#3D3D3D')};
  }
`

interface Props {
  changeMethod: (e: Methods) => void
  handlePlaidSuccess?: (token: string, meta: any) => void
  method: string
  savedCard?: string
  onExit?: () => void
}

export default function PaymentMethods(props: Props) {
  const { method, changeMethod, handlePlaidSuccess, savedCard } = props
  return (
    <section className="payment-methods">
      <h2 className="title">Select Payment Method</h2>
      <div className="cards">
        {PAYMENT_METHODS.map((item: any, index) => {
          return (
            <Card
              key={index}
              onClick={() => item.name !== 'paypal' && changeMethod(item.name)}
              className={method === item.name ? `card active ${item.name}` : `card inactive ${item.name}`}
            >
              {item.name === 'bank_account' && savedCard !== 'bank_account' ? (
                <PlaidLink
                  clientName="CivicChamps"
                  env="sandbox"
                  product={['auth', 'transactions']}
                  publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY as string}
                  onSuccess={(token: any, meta: any) => handlePlaidSuccess && handlePlaidSuccess(token, meta)}
                  onExit={() => props.onExit && props.onExit()}
                >
                  <img src={`/assets/images/${item.icon}`} alt={item.title} />
                  <p>{item.title}</p>
                </PlaidLink>
              ) : (
                <>
                  <img src={`/assets/images/${item.icon}`} alt={item.title} />
                  <p>{item.title}</p>
                </>
              )}
            </Card>
          )
        })}
      </div>
    </section>
  )
}

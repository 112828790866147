import * as React from 'react'
import './style.scss'

interface Props {
  onChange: () => void
  monthly: boolean
}

export default function IsMonthly(props: Props) {
  const { onChange, monthly } = props
  return (
    <div className="component-is-monthly">
      <p>ONE TIME</p>
      <label className={`monthly-switch ${monthly && 'active'}`} onClick={() => onChange()}>
        <span className="slider" />
      </label>
      <p>MONTHLY</p>
    </div>
  )
}

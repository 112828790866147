import * as React from 'react'
import './style.scss'
import PageHeader from '../../modules/common/page-header'
import NonprofitFooter from '../../modules/nonprofit/footer'
import NonprofitContent from '../../modules/nonprofit/content'
import { Link } from 'react-router-dom'
import Button from '../../components/button'

export default function NonProfit(props: any) {
  let path = props.location.pathname
  path.endsWith('/') && (path = path.slice(0, -1))
  return (
    <article className="non-profit">
      <PageHeader title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p className="slogan">
          Use your Civic Champs account to quickly <br />
          make and track your donations.
        </p>
        <Button color="primary" label="Login" isLink={true} to={path + '/login'} />
        <Button color="secondary" label="Create Account" isLink={true} to={path + '/sign-up/'} />
        <Link className="continue" to="without-account">
          Continue without an account
        </Link>
      </NonprofitContent>
      <NonprofitFooter />
    </article>
  )
}

import { CardCvcElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'
import * as React from 'react'
import { useState } from 'react'
import CardError from '../../../components/cardError'

interface Props {
  onReady: (e: boolean) => void
}


function CardForm({ numberChange, expiryChange, cvcChange }: CardFormProps) {
  const numberRef = React.useRef<any>({})
  const expiryRef = React.useRef<any>({})
  const cvcRef = React.useRef<any>({})
  const inputStyle = {
    base: {
      fontSize: '18px',
      color: '#777777',
      fontFamily: 'Open Sans Light, sans-serif',
      '::placeholder': {
        color: '#777777',
        fontFamily: 'Open Sans Light, sans-serif',
        fontSize: '16px',
        fontWeight: 200,
      },
    },
  }
  return (
    <>
      <CardNumberElement
        className="input"
        placeholder="Credit card number"
        ref={numberRef}
        onChange={(e: any) => {
          numberChange(e)
          e.complete && expiryRef.current._element.focus()
        }}
        style={inputStyle}
        onReady={el => el.focus()}
      />

      <div className="selects">
        <div className="half">
          <CardExpiryElement
            ref={expiryRef}
            onChange={e => {
              expiryChange(e)
              e.complete && cvcRef.current._element.focus()
              e.empty && numberRef.current._element.focus()
            }}
            style={inputStyle}
          />
        </div>
        <div className="half">
          <CardCvcElement
            ref={cvcRef}
            onChange={e => {
              cvcChange(e)
              e.empty && expiryRef.current._element.focus()
            }}
            style={inputStyle}
          />
        </div>
      </div>
    </>
  )
}

function StripeCardForm(props: Props) {
  const [creditCard, setCreditCard] = useState({
    error_cardNumber: '',
    error_cardExpiry: '',
    error_cardCvc: '',
    complete_cardNumber: false,
    complete_cardExpiry: false,
    complete_cardCvc: false,
  })

  const cardChange = (e: any, elType: 'cardNumber' | 'cardExpiry' | 'cardCvc') => {
    setCreditCard({ ...creditCard, [`complete_${elType}`]: e.complete, [`error_${elType}`]: e.error ? e.error.message : '' })
  }

  if ((creditCard.complete_cardNumber && creditCard.complete_cardExpiry && creditCard.complete_cardCvc)) {
    props.onReady(true)
  }

  return (
    <div className="card-form">
      <CardForm
        numberChange={e => cardChange(e, 'cardNumber')}
        expiryChange={e => cardChange(e, 'cardExpiry')}
        cvcChange={e => cardChange(e, 'cardCvc')}
      />
      {!!creditCard.error_cardNumber && <CardError error={creditCard.error_cardNumber} />}
      {!!creditCard.error_cardExpiry && <CardError error={creditCard.error_cardExpiry} />}
      {!!creditCard.error_cardCvc && <CardError error={creditCard.error_cardCvc} />}
    </div>
  )
}

interface CardFormProps {
  numberChange: (e: any) => void
  expiryChange: (e: any) => void
  cvcChange: (e: any) => void
}

export default React.memo(StripeCardForm)

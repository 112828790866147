import * as React from 'react'
import './style.scss'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface Props {
  color: 'primary' | 'secondary' | 'warning'
  label: string
  width?: number
  isLink?: true | false
  to?: string
  className?: string
  disabled?: boolean
  onClick?: () => void
}

function Button(props: Props) {
  const { label, color, width, isLink, to, disabled, onClick, className } = props
  const Btn = styled.button`
    ${() => width && `width: ${width}px`}
    ${props => props.disabled && 'opacity: 0.6;'}
  `

  return isLink ? (
    <Link
      className={`component-button ${color} ${className ? className : ''}`}
      to={to!}
      style={{opacity: props.disabled ? 0.6 : 1}}
      onClick={e => props.disabled && e.preventDefault()}
    >
      {label}
    </Link>
  ) : (
    <Btn
      disabled={disabled}
      className={`component-button ${color} ${className ? className : ''}`}
      onClick={() => onClick && onClick()}
    >
      {label}
    </Btn>
  )
}
export default React.memo(Button)

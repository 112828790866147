import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Error from './components/error'
import Thanks from './pages/thanks'
import Donation from './pages/donation'
import Home from './pages/home'
import BaseService from './services/base.service'
import SubscriptionInner from './pages/subscriptions/inner/index'
import EditSubscription from './pages/subscriptions/inner/edit'
import Subscriptions from './pages/subscriptions'
import CardEdit from './pages/subscriptions/inner/card-edit'
import StripeConnect from './pages/connect'
import NonProfit from './pages/nonprofit'
import Login from './pages/nonprofit/auth/login'
import WithoutAccount from './pages/nonprofit/without-account'

// with Redux
import { _NonProfitDonation, _PaymentInfo, _DonationSummary, _NonProfitThanks, _WithoutAccount, _NonProfitCampaign } from './redux/containers/NonProfitDonationContainer'
import { _SignUp, _SignUpSecondStep, _SignUpThirdStep } from './redux/containers/UserInfoContainer'
import CampaignLanding from './pages/nonprofit/auth/campaign'

const baseService = new BaseService()

interface Params {
  hours: string
  org_name: string
  org_id: string
  userId: string
  access_token: string
  app_link: string
  auth?: string
  opportunity_id?: number
}

export default function Routes() {
  const url = window.location.search.substring(1)
  const params: Params | null = url
    ? JSON.parse(
        '{"' +
          decodeURI(url)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      )
    : null

  if (params && params.auth) {
    const { auth, ...rest } = params

    // clear sensitive data from url
    if (window.location.pathname.includes('subscriptions')) {
      window.history.pushState({}, 'Civic Champs', '/subscriptions/')
    } else {
      // set current session to localStorage
      baseService.setSession(rest)
      window.history.pushState({}, 'Civic Champs', '/')
    }
  }
  const session = baseService.currentSession()
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" render={props => <Home {...props} {...session} />} />
        <Route exact path="/donation" render={props => <Donation {...props} {...session} />} />
        <Route exact path="/thanks" render={props => <Thanks {...props} {...session} />} /> */}
        <Route exact path="/subscriptions" render={props => <Subscriptions {...props} {...session} />} />
        <Route exact path="/subscriptions/:id" render={props => <SubscriptionInner {...props} {...session} />} />
        <Route exact path="/subscriptions/:id/edit" render={props => <EditSubscription {...props} />} />
        <Route exact path="/subscriptions/:id/card-edit" render={props => <CardEdit {...props} {...session} />} />
        <Route exact path="/connect" render={props => <StripeConnect {...props} />} />
        <Route exact path="/unauthorized" render={props => <Error error="Session timed out" {...props} />} />

        <Route exact path="/nonprofit/:id/campaign" render={props => <_NonProfitCampaign {...props} />} />
        <Route exact path="/nonprofit/:id/" render={props => <NonProfit {...props} />} />
        <Route exact path="/nonprofit/:id/login" render={props => <Login {...props} />} />
        <Route exact path="/nonprofit/:id/sign-up/" render={props => <_SignUp {...props} />} />
        <Route exact path="/nonprofit/:id/sign-up/second-step" render={props => <_SignUpSecondStep {...props} />} />
        <Route exact path="/nonprofit/:id/sign-up/third-step" render={props => <_SignUpThirdStep {...props} />} />
        <Route exact path="/nonprofit/:id/without-account" render={props => <_WithoutAccount {...props} />} />
        <Route exact path="/nonprofit/:id/donation" render={props => <_NonProfitDonation {...props} />} />
        <Route exact path="/nonprofit/:id/payment-info" render={props => <_PaymentInfo {...props} />} />
        <Route exact path="/nonprofit/:id/final" render={props => <_DonationSummary {...props} />} />
        <Route exact path="/nonprofit/:id/thanks" render={props => <_NonProfitThanks {...props} />} />

        <Route render={props => <Error error="This page doesn't exist" {...props} />} />
      </Switch>
    </Router>
  )
}

import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../../modules/common/page-header'
import NonprofitContent from '../../../../../modules/nonprofit/content'
import Input from '../../../../../components/input'
import Button from '../../../../../components/button'
import NonprofitFooter from '../../../../../modules/nonprofit/footer'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import BaseService from '../../../../../services/base.service'
import Loader from '../../../../../components/loader'

const base = new BaseService()
export default function SignUpThirdStep(props: any) {
  const [isError, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const verify = async () => {
    setLoading(true)
    try {
      const res = await Auth.confirmSignUp(props.userInfo.username, props.userInfo.code)

      if (res === 'SUCCESS') {
        await Auth.signIn(props.userInfo.username, props.userInfo.password)
        const currentUser = await Auth.currentUserInfo()
        const response = await base.postJSON('auth', {
          userId: currentUser.attributes.sub,
          email: currentUser.attributes.email,
          organizationId: props.match.params.id
        })
        base.setStorageByKey('access_token', response.access_token)
        setLoading(false)
        return props.history.push('../donation')
      } else {
        return setLoading(false)
      }
    } catch (error) {
      console.log('error ', error)
      setError(error.message)
      setLoading(false)
    }
  }

  const resend = async () => {
    try {
      setError('')
      await Auth.resendSignUp(props.userInfo.username)
    } catch (e) {
      console.log('error ', e)
      setError(e.message)
    }
  }

  return (
    <article className="non-profit-signup third-step">
      <PageHeader goBack title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p>Please enter the verification code we just sent you.</p>
        <Input
          type="text"
          placeholder="Code"
          value={props.userInfo.code}
          onChange={e => {
            setError('')
            props.setUserInfo('code', e)
          }}
          error={!!isError}
          errorMessage={isError}
        />

        <Button color="primary" label="Verify Account" onClick={verify} />
        <button className="resend" onClick={resend}>
          Resend Code
        </button>
      </NonprofitContent>
      {loading && <Loader/>}
      <NonprofitFooter />
    </article>
  )
}

import BaseService, { JsonResponse } from './base.service'

const base = new BaseService()

interface Donation {
  amount: number
  saveCard: boolean
  isMonthly: boolean
  stripe_token: string
  organizationStripeAccountId: number
  fees: number
  feesPaidByDonor: boolean
  campaignId?: number
  opportunityId?: number
}

export default class DonationService {
  roundUp = (num: number, precision: number) => {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
  }

  calcNet = (amount: number): number => {
    return Number(this.roundUp(amount * 0.971 - 0.3, 2).toFixed(2))
  }

  calcFees = (amount: number): number => {
    return Number(this.roundUp((amount + 0.3) / 0.971, 2).toFixed(2))
  }

  getOrganization = async (id: number) => {
    return await base.getJSON(`/donation/organization_stripe_account/${id}`)
  }

  charge = async (donation: Donation) => {
    const res: JsonResponse = await base.postJSON('donation/donate', {
      stripeToken: donation.stripe_token,
      saveCard: donation.saveCard,
      amountInCents: Math.round(donation.amount * 100),
      isMonthly: donation.isMonthly,
      organizationStripeAccountId: donation.organizationStripeAccountId,
      fees: donation.fees,
      feesPaidByDonor: donation.feesPaidByDonor,
      campaignId: donation.campaignId,
      opportunityId: donation.opportunityId
    })
    if (res && res.success) {
      base.setAmount(donation.amount)
    }
    return res
  }

  chargeSavedCard = async (donation: Donation) => {
    const res: any = await base.postJSON('donation/donate-by-saved-card', {
      amountInCents: Math.round(donation.amount * 100),
      isMonthly: donation.isMonthly,
      organizationStripeAccountId: donation.organizationStripeAccountId,
      fees: donation.fees,
      feesPaidByDonor: donation.feesPaidByDonor,
      campaignId: donation.campaignId,
      opportunityId: donation.opportunityId
    })

    if (res.success) {
      base.setAmount(donation.amount)
    }

    return res
  }

  nonPriftCharge = async (e: any) => {
    return {}
  }
}

import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../../modules/common/page-header'
import NonprofitContent from '../../../../../modules/nonprofit/content'
import Input from '../../../../../components/input'
import Button from '../../../../../components/button'
import NonprofitFooter from '../../../../../modules/nonprofit/footer'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Auth } from 'aws-amplify'
import { validate } from '../../../../../helpers'
import moment from 'moment'

export default function SignUpSecondStep(props: any) {
  const getUsername = () =>
    validate.phoneNumber(props.userInfo.username) ? '+1' + props.userInfo.username : props.userInfo.username

  const signUp = async () => {
    try {
      await Auth.signUp({
        username: getUsername(),
        password: props.userInfo.password,
        attributes: {
          email: validate.email(props.userInfo.username) ? props.userInfo.username : '',
          given_name: props.userInfo.given_name,
          family_name: props.userInfo.last_name,
          phone_number: validate.phoneNumber(props.userInfo.username) ? '+1' + props.userInfo.username : '',
          birthdate: props.userInfo.birthday,
        },
        validationData: [],
      })
      props.history.push('third-step')
    } catch (error) {
      console.log('error ', error)
    }
  }

  return (
    <article className="non-profit-signup second-step">
      <PageHeader goBack title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p>Next we need to know a little more about you.</p>
        <Input
          type="text"
          placeholder="First Name"
          value={props.userInfo.given_name}
          onChange={e => props.setUserInfo('given_name', e)}
        />
        <Input
          type="text"
          placeholder="Last Name"
          value={props.userInfo.last_name}
          onChange={e => props.setUserInfo('last_name', e)}
        />
        <ReactDatePicker
          onChange={(date: any) => {
            props.setUserInfo('birthday', moment(date).format('MM/DD/YYYY'))
          }}
          placeholderText="Birthday"
          value={props.userInfo.birthday || ''}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          maxDate={moment().subtract(14, 'years').toDate()}
          minDate={moment().subtract(100, 'years').toDate()}
          dropdownMode="select"
        />

        <Button
          disabled={!props.userInfo.given_name || !props.userInfo.last_name || !props.userInfo.birthday}
          color="primary"
          label="Continue"
          to="third-step"
          onClick={signUp}
        />
      </NonprofitContent>

      <NonprofitFooter />
    </article>
  )
}

import * as React from 'react'
import './style.scss'

interface Props {
  placeholder: string
  type: string
  onChange?: (e: string) => void
  enterPress?: () => void
  onBlur?: () => void
  className?: string
  name?: string
  error?: boolean
  errorMessage?: string
  value?: string | number
}

export default function Input(props: Props) {
  const { type, onBlur, placeholder, onChange, className, name, value, error, errorMessage, enterPress } = props
  return (
    <div className='component-input'>
      <input
        className={`${className ? className : ''} ${error ? 'error' : ''}`}
        name={name}
        placeholder={placeholder}
        value={value || ''}
        type={type || 'text'}
        onBlur={onBlur && onBlur}
        onKeyDown={(e) => e.key === 'Enter' && enterPress && enterPress()}
        onChange={e => onChange && onChange(e.target.value)}
      />
      {error && (
        <p className="card-error">
          <img src="/assets/images/round-error.svg" alt="error" />
          {errorMessage}
        </p>
      )}
    </div>
  )
}

import * as React from 'react'
import './style.scss'

export default function PageHeader(props: any) {
  const { title, subtitle, goBack } = props
  return (
    <header className="module-page-header">
      <nav className="main-menu">
        {goBack && (
          <div className="go-back" onClick={() => window.history.back()}>
            <img src="/assets/images/arrow-back.svg" alt="home-icon" />
          </div>
        )}
        <img className="logo" src="/assets/images/logo.png" alt="logo" />
      </nav>
      {!!title && <h1>{title}</h1>}
      {!!subtitle && <h2>{subtitle}</h2>}
    </header>
  )
}

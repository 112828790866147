import * as React from 'react'
import './style.scss'
import { useEffect, useState } from 'react'
import PageHeader from '../../../../modules/common/page-header'
import NonprofitContent from '../../../../modules/nonprofit/content'
import CheckBox from '../../../../components/checkbox'
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import NonprofitFooter from '../../../../modules/nonprofit/footer'
import PaymentMethods from '../../../../modules/donation/payment-methods/indext'
import { Elements, injectStripe } from 'react-stripe-elements'
import { Methods } from '../../../../interfaces'
import StripeCardForm from '../../../../modules/common/stripe-card-form'
import { Auth } from 'aws-amplify'
import BaseService from '../../../../services/base.service'
import SavedCard from '../../../../modules/donation/saved-card'
import RemoveCard from '../../../../modules/donation/remove-card'
import DonationService from '../../../../services/donation.service'
import Loader from '../../../../components/loader'

const baseService = new BaseService()
const donationService = new DonationService()

function _PaymentInfo(props: any) {
  const [matching, setMatching] = useState(false)
  const [showRemoveCard, setShowRemoveCard] = useState(false)
  const [cardFilled, setCardFilled] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const setUser = async () => {
      setLoading(true)
      // const currentUser = await Auth.currentUserInfo()
      const currentUser = (await baseService.getJSON('donation/user')) || {}
      // if (!currentUser) {
      //   window.location.replace(`/nonprofit/${props.match.params.id}`)
      // }
      const {
        email, sub, given_name, family_name,
      } = currentUser
      const res = await baseService.getJSON('donation/stripe-sources')

      props.setNonProfit('firstName', given_name)
      props.setNonProfit('lastName', family_name)
      if (!!res && res.success) {
        res.data[0].object === 'bank_account' && props.setNonProfit('method', 'bank_account')
        props.setNonProfit('savedCard', res.data[0])
      }
      props.setNonProfit('user', { email: email || 'test@civicchamps.com', userId: sub || 'test_sub' })
      return setLoading(false)
    }
    setUser()
  }, [])

  const handlePlaidSuccess = (token: string, meta: any) => {
    props.setNonProfit('plaid', { public_token: token, account_id: meta.accounts[0].id })
    return props.setNonProfit('savedCard', {
      bank_name: meta.institution.name,
      last4: meta.accounts[0].mask,
      brand: null,
      id: meta.accounts[0].id,
      object: method,
      notSaved: true,
    })
  }

  const removeSavedCard = async () => {
    setLoading(true)
    const res = await baseService.postJSON('donation/remove-saved-card', {
      customer: props.nonProfit.savedCard.customer,
      cardId: props.nonProfit.savedCard.id,
    })

    if (res.success) {
      setLoading(false)
      props.setNonProfit('savedCard', {})
    }
    setLoading(false)
  }

  const validateFields = () => {
    const { savedCard } = props.nonProfit
    if (
      !!(
        props.nonProfit.firstName &&
        props.nonProfit.lastName &&
        props.nonProfit.address &&
        props.nonProfit.city &&
        props.nonProfit.state &&
        props.nonProfit.zip
      )
    ) {
      if (
        (props.nonProfit.method === 'card' && cardFilled) ||
        (!!savedCard.id && savedCard.object === 'card')
      )
        return true
      if (
        (props.nonProfit.method === 'bank_account' && props.nonProfit.plaid.public_token) ||
        (!!savedCard.id && savedCard.object === 'bank_account')
      )
        return true
      return ['google', 'apple'].includes(props.nonProfit.method)
    }
  }

  const finalizePayment = async () => {
    const { savedCard } = props.nonProfit
    if (method === 'card' && !savedCard.id) {
      const { token } = await props.stripe.createToken({ 
        address_line1: props.nonProfit.address, 
        address_city: props.nonProfit.city, 
        address_state: props.nonProfit.state, 
        address_zip: props.nonProfit.zip }) 
      props.setNonProfit('stripe_token', token.id)
    }
    return props.history.push('final')
  }

  let {
    method,
    savedCard,
    firstName,
    lastName,
    address,
    city,
    includeFees,
    saveCard,
    state,
    zip,
    amount,
    corporate,
    organization,
  } = props.nonProfit

  amount = includeFees ? donationService.calcFees(amount) : amount
  if(savedCard!=null && savedCard.id && savedCard.object === method){
    if(address === "" && savedCard.address_line1!=null){props.setNonProfit('address', savedCard.address_line1)}
    if(city === "" && savedCard.address_city!=null){ props.setNonProfit('city', savedCard.address_city)}
    if(state === "" && savedCard.address_state!=null){props.setNonProfit('state',  savedCard.address_state)}
    if(zip === "" && savedCard.address_zip!=null){props.setNonProfit('zip',  savedCard.address_zip)}
}

  return props.nonProfit.user.userId ? (
    <article className="non-profit-donation-payment-info">
      <PageHeader goBack title={organization.name} subtitle="Donation" />
      <NonprofitContent scrollable={true}>
        <div className="payment-info">
          <h2>Payment Information</h2>
          <p>${amount.toFixed(2)}</p>
        </div>

        {/* <PaymentMethods
          handlePlaidSuccess={(token, meta) => handlePlaidSuccess(token, meta)}
          savedCard={savedCard.object}
          method={method}
          changeMethod={(m: Methods) => {
            props.setNonProfit('method', m)
          }}
          onExit={() => props.setNonProfit('method', 'card')}
        /> */}
        <fieldset className="payment-form">
          {savedCard.id && savedCard.object === method ? (
            <SavedCard savedCard={savedCard} onShowRemoveCard={() => setShowRemoveCard(true)} />
          ) : (
            method === 'card' && <StripeCardForm onReady={e => setCardFilled(e)} />
          )}
          <div className="half-inputs">
            <Input
              value={firstName}
              onChange={e => (/^[A-Za-z]+$/.test(e) || e === '') && props.setNonProfit('firstName', e)}
              className="person-info"
              type="text"
              placeholder="First Name*"
            />
            <Input
              value={lastName}
              onChange={e => (/^[A-Za-z]+$/.test(e) || e === '') && props.setNonProfit('lastName', e)}
              className="person-info"
              type="text"
              placeholder="Last Name*"
            />
          </div>
          <Input
            className="person-info"
            value={address}
            onChange={e => props.setNonProfit('address', e)}
            type="text"
            placeholder="Address*"
          />

          <div className="address-inputs">
            <Input
              value={city}
              onChange={e => props.setNonProfit('city', e)}
              className="city-info"
              type="text"
              placeholder="City*"
            />
            <Input
              value={state}
              onChange={e => props.setNonProfit('state', e)}
              className="address-info"
              type="text"
              placeholder="ST*"
            />
            <Input
              value={zip}
              onChange={e => props.setNonProfit('zip', e)}
              className="zip-code"
              type="text"
              placeholder="ZIP*"
            />
          </div>
        </fieldset>
        <fieldset className="checkbox-inputs">
          <CheckBox checked={includeFees} onClick={() => props.setNonProfit('includeFees', !includeFees)}>
            <p>Cover the processing fees</p>
          </CheckBox>
          {(!savedCard.id || savedCard.object !== method) && (
              <CheckBox checked={saveCard} onClick={() => props.setNonProfit('saveCard', !saveCard)}>
                <p>Save for faster donations later</p>
              </CheckBox>
            )}
          {/* <CheckBox checked={matching} onClick={() => setMatching(!matching)}>
            <p>Company Matching</p>
          </CheckBox> */}

          {matching && (
            <Input
              onChange={e => props.setNonProfit('corporate', e)}
              value={corporate}
              className="person-info"
              type="text"
              placeholder="Company Name"
            />
          )}
        </fieldset>

        <Button
          disabled={!validateFields()}
          onClick={() => finalizePayment()}
          color="primary"
          label="Continue"
          className="continue"
        />
      </NonprofitContent>
      <NonprofitFooter />

      {showRemoveCard && (
        <RemoveCard
          onClose={() => setShowRemoveCard(false)}
          onRemove={async () => {
            if(savedCard.notSaved) {
              props.setNonProfit('savedCard', {})
              props.setNonProfit('method', 'card')
            } else {
              await removeSavedCard()
            }
            return setShowRemoveCard(false)
          }}
        />
      )}
      {loading && <Loader />}
    </article>
  ) : (
    <Loader />
  )
}

const Injected = injectStripe(React.memo(_PaymentInfo))

export default function PaymentInfo(props: any) {
  return (
    <Elements>
      <Injected {...props} />
    </Elements>
  )
}

import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../modules/common/page-header'
import NonprofitContent from '../../../../modules/nonprofit/content'
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import NonprofitFooter from '../../../../modules/nonprofit/footer'
import { useState } from 'react'
import { validate } from '../../../../helpers'
import {Link} from 'react-router-dom'

export default function SignUp(props: any) {
  const [error_username, set_error_username] = useState('')
  const [error_password, set_error_password] = useState('')
  const [error_passwordVerify, set_error_passwordVerify] = useState('')
  const [verifyPassword, set_verifyPassword] = useState('')

  const { userInfo: { password, username }} = props

  const usernameError = 'Please enter a valid email address or phone number'
  const passwordError = 'Password must be at least 8 characters and contain at least 1 number and 1 letter'
  const requiredError = 'Required field'

  const empty = (str: string) => str.length === 0

  const valid = () => {
    if(empty(password) || empty(username) || empty(verifyPassword)) return false
    return !(!empty(error_username) || !empty(error_password) || !empty(error_passwordVerify) || verifyPassword !== password);
  }

  const validateUsername = () => {
    if(username.length === 0) {
      return set_error_username(requiredError)
    }
    if(username.includes('@')) {
      return validate.email(username) ? null : set_error_username(usernameError)
    }
    return validate.phoneNumber(username) ? null : set_error_username(usernameError)
  }

  return (
    <article className="non-profit-signup">
      <PageHeader goBack title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p className="slogan">First we need login information for your new account.</p>
        <Input
          type="text"
          value={username}
          onChange={e => {
            props.setUserInfo('username', e)
            set_error_username('')
          }}
          onBlur={validateUsername}
          placeholder="Email or Phone"
          error={!!error_username}
          errorMessage={error_username}
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            props.setUserInfo('password', e)
            set_error_password('')
          }}
          onBlur={() => !validate.password(password) && set_error_password(passwordError)}
          placeholder="Password"
          error={!!error_password}
          errorMessage={error_password}
        />
        <Input
          type="password"
          placeholder="Verify Password"
          value={verifyPassword}
          onChange={e => {
            set_verifyPassword(e)
            set_error_passwordVerify('')
          }}
          onBlur={() => password !== verifyPassword && set_error_passwordVerify('Password must match')}
          error={!!error_passwordVerify}
          errorMessage={error_passwordVerify}
        />
        <Button disabled={!valid()} onClick={() => props.history.push('second-step')} color="primary" label="Continue" />

        <Link className="sign-in" to="../login">
          Login
        </Link>
      </NonprofitContent>
      <NonprofitFooter />
    </article>
  )
}

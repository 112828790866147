import * as React from 'react'
import './style.scss'
import PageHeader from '../../../modules/common/page-header'
import NonprofitContent from '../../../modules/nonprofit/content'
import Input from '../../../components/input'
import Button from '../../../components/button'
import NonprofitFooter from '../../../modules/nonprofit/footer'
import { validate } from '../../../helpers'
import { useState } from 'react'

export default function WithoutAccount(props: any) {
  const [error_first, set_error_first] = useState('')
  const [error_last, set_error_last] = useState('')
  const [error_email, set_error_email] = useState('')

  const valid = () => {
    return !!(props.nonProfit.firstName && props.nonProfit.lastName && props.nonProfit.user.email) && validate.email(props.nonProfit.user.email)
  }

  const validateEmail = () => {
    if (props.nonProfit.user.email.length === 0) {
      return set_error_email('Required field')
    }
    return validate.email(props.nonProfit.user.email) ? null : set_error_email('Please enter a valid email')
  }

  const validateFirstName = () => {
    if (props.nonProfit.firstName.length === 0) {
      return set_error_first('Required field')
    }
    return true
  }

  const validateLastName = () => {
    if (props.nonProfit.firstName.length === 0) {
      return set_error_last('Required field')
    }
    return true
  }

  return (
    <article className="non-profit-signup second-step">
      <PageHeader title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p>Next we need to know a little more about you.</p>
        <Input
          onBlur={validateFirstName}
          error={!!error_first}
          errorMessage={error_first}
          onChange={e => {
            set_error_first('')
            props.setNonProfit('firstName', e)
          }}
          type="text"
          placeholder="First Name"
          value={props.nonProfit.firstName}
        />
        <Input
          value={props.nonProfit.lastName}
          onBlur={validateLastName}
          error={!!error_last}
          errorMessage={error_last}
          onChange={e => {
            set_error_last('')
            props.setNonProfit('lastName', e)
          }}
          type="text"
          placeholder="Last Name"
        />
        <Input
          value={props.nonProfit.user.email}
          onChange={e => {
            set_error_email('')
            props.setNonProfit('user', { ...props.nonProfit.user, email: e })
          }}
          onBlur={validateEmail}
          error={!!error_email}
          errorMessage={error_email}
          type="email"
          placeholder="Email Address"
        />
        <Input type="text" placeholder="Phone (optional)" />
        <Button disabled={!valid()} color="primary" label="Continue" isLink={true} to="donation" />
      </NonprofitContent>
      <NonprofitFooter />
    </article>
  )
}

import * as React from 'react'
import './style.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import BaseService from '../../../services/base.service'
import YearReportPopup from '../../../modules/subscriptions/year-report-popup'
import Error from '../../../components/error'
import { useState } from 'react'
import { useEffect } from 'react'

const baseService = new BaseService()

export default function SubscriptionInner(props: any) {
  const [data, setData] = useState<any | null>(null)
  const [availableYears, setAvailableYears] = useState([])
  const [error, setError] = useState('')
  const [showReport, setReport] = useState(false)
  const [reportYear, setReportYear] = useState(0)

  const setShowReport = (status: boolean, year: number) => {
    setReport(status)
    setReportYear(year)
  }

  useEffect(() => {
    const getSubscription = async () => {
      let res = await baseService.getJSON(`donation/subscription/${props.match.params.id}`)
      if (res.success) {
        setData(res.data)
      } else {
        setError(res.message)
        setData({})
      }
      res = await baseService.getJSON(
        `donation/years/${res.data.subscription.corporate}/subscription/${props.match.params.id}`,
      )
      setAvailableYears([])
    }

    getSubscription()
    // eslint-disable-next-line
  }, [])

  return (
    data && (
      <main className="screen subscription-inner-page">
        <header className="header">
          <nav className="main-menu">
            <span className="home" onClick={() => window.history.back()}>
              <img src="/assets/images/arrow-back.svg" alt="home-icon" />
            </span>
            <img src="/assets/images/logo.png" alt="logo" />
          </nav>
        </header>

        <section className="sub-header">
          <h1>Monthly Donations</h1>
          <p>Subscription View</p>
        </section>
        {error ? (
          <Error error={error} onClose={() => window.history.back()} />
        ) : (
          <>
            <section className="content">
              <h2>{data.subscription.corporate}</h2>
              <div className="amount">
                ${(data.subscription.amount / 100).toFixed(2)}{' '}
                <Link to={`/subscriptions/${data.subscription.id}/edit`}>
                  <img src="/assets/images/square-edit.svg" alt="edit" />
                </Link>
              </div>
              <div className="status">
                <p>
                  First Donation: <b>{moment(data.firstDonation * 1000).format('MM/DD/YY')}</b>
                </p>
                <p>
                  Next Donation:{' '}
                  <b>{data.subscription.paused ? 'Paused' : moment(data.nextDonation * 1000).format('MM/DD/YY')}</b>
                </p>
                <p>
                  Total Donations: <b>${(data.totalDonations / 100).toFixed(2)}</b>
                </p>
              </div>
            </section>

            <section className="yearly">
              <h2>Yearly reports</h2>
              <div className="btns">
                {availableYears.map(year => (
                  <button key={year} onClick={() => setShowReport(true, year)}>
                    {year}
                  </button>
                ))}
              </div>
            </section>

            {showReport && (
              <YearReportPopup
                corporate={data.subscription.corporate}
                id={data.subscription.id}
                totalDonations={(data.totalDonations / 100).toFixed(2)}
                year={reportYear}
                onClose={() => setShowReport(false, 0)}
              />
            )}
          </>
        )}
      </main>
    )
  )
}

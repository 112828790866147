import * as React from 'react'
import './style.scss'

export default function SavedCard({ savedCard, onShowRemoveCard }: any) {
  const possibleCards = ['MasterCard', 'American Express', 'Visa', 'Discover']
  return (
    <div className="saved-card">
      <label>Donation Source</label>
      <div className="source">
        {possibleCards.some(card => savedCard.brand === card) ? (
          <img src={`/assets/images/${savedCard.brand.replace(/ /g, '_')}.png`} alt="" />
        ) : (
          <span className="bank-name">{savedCard.bank_name}</span>
        )}
        <div className="card-details">
          <div className="digits">**** **** **** {savedCard.last4}</div>
          <div className="brand">{savedCard.brand}</div>
        </div>
        <span className="remove-card" onClick={() => onShowRemoveCard()}>
          &#10799;
        </span>
      </div>
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import BaseService from '../../services/base.service'

const baseService = new BaseService()

export default function StripeConnect(props: any) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  useEffect(() => {
    const code = props.location.search.split('code=')[1]
    if (!code) {
      setError('Code is required')
      return
    }
    const connectOrganization = async () => {
      const res = await baseService.postJSON('organization/connect', {
        code: code,
      })

      if (res.success) {
        return setSuccess(true)
      }
      setError('Authorization code expired')
      return null
    }
    connectOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (success) {
    setTimeout(() => {
      window.location.href = 'https://admin.civicchamps.com/'
    }, 3000)
  }

  return success ? (
    <p>You successfully connected to CivicChamps portal!!! You will be redirected to main page in 3 seconds!!</p>
  ) : error.length > 0 ? (
    <p>Error: {error}</p>
  ) : (
    <p>In progress</p>
  )
}

import { connect } from 'react-redux'
import SignUp from '../../pages/nonprofit/auth/sign-up'
import SignUpSecondStep from '../../pages/nonprofit/auth/sign-up/second-step'
import SignUpThirdStep from '../../pages/nonprofit/auth/sign-up/third-step'
import { UserInfoKeys, setUserInfo } from '../reducers/UserInfo'

function MSTPUserInfo(state: any) {
  return {
    ...state.SignUp,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setUserInfo: (key: UserInfoKeys, val: number | string | boolean) => dispatch(setUserInfo(key, val)),
  }
}

export const _SignUp = connect(
  MSTPUserInfo,
  mapDispatchToProps,
)(SignUp)

export const _SignUpSecondStep = connect(
  MSTPUserInfo,
  mapDispatchToProps,
)(SignUpSecondStep)

export const _SignUpThirdStep = connect(
  MSTPUserInfo,
  mapDispatchToProps,
)(SignUpThirdStep)

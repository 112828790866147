import * as React from 'react'
import './style.scss'

export default function NonprofitFooter() {
  return (
    <footer className="nonprofit-footer">
      <img src="/assets/images/text-logo.png" alt="" />
    </footer>
  )
}

import { CardCvcElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'
import * as React from 'react'

interface Props {
  numberChange: (e: any) => void
  expiryChange: (e: any) => void
  cvcChange: (e: any) => void
}

export default function CardForm({ numberChange, expiryChange, cvcChange }: Props) {
  const numberRef = React.useRef<any>({})
  const expiryRef = React.useRef<any>({})
  const cvcRef = React.useRef<any>({})
  const inputStyle = {
    base: {
      fontSize: '18px',
      color: '#777777',
      fontFamily: 'Open Sans Light, sans-serif',
      '::placeholder': {
        color: '#777777',
        fontFamily: 'Open Sans Light, sans-serif',
        fontSize: '16px',
        fontWeight: 200,
      },
    },
  }
  return (
    <div className="card-form">
      <CardNumberElement
        className="input"
        placeholder="Credit card number"
        ref={numberRef}
        onChange={(e: any) => {
          numberChange(e)
          e.complete && expiryRef.current._element.focus()
        }}
        style={inputStyle}
        onReady={el => el.focus()}
      />

      <div className="selects">
        <div className="half">
          <CardExpiryElement
            ref={expiryRef}
            onChange={e => {
              expiryChange(e)
              e.complete && cvcRef.current._element.focus()
              e.empty && numberRef.current._element.focus()
            }}
            style={inputStyle}
          />
        </div>
        <div className="half">
          <CardCvcElement
            ref={cvcRef}
            onChange={e => {
              cvcChange(e)
              e.empty && expiryRef.current._element.focus()
            }}
            style={inputStyle}
          />
        </div>
      </div>
    </div>
  )
}

const SIGN_UP = 'SIGN_UP'

const initialState = {
  userInfo: {
    username: '',
    password: '',
    given_name: '',
    last_name: '',
    birthday: '',
    code: '',
  },
}

export type UserInfoKeys = 'username' | 'password' | 'given_name' | 'last_name' | 'birthday'

export function setUserInfo(key: UserInfoKeys, e: boolean | string | number) {
  return { type: SIGN_UP, val: e, key }
}

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SIGN_UP:
      return { userInfo: { ...state.userInfo, [action.key]: action.val } }

    default:
      return state
  }
}

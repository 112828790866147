import * as React from 'react'
import './style.scss'
import { Elements, injectStripe } from 'react-stripe-elements'

import BaseService from '../../../../services/base.service'
import CheckBox from '../../../../components/checkbox'
// import Error from '../../../../components/error'
import RemoveCard from '../../../../modules/donation/remove-card'
import PaymentMethods from '../../../../modules/donation/payment-methods/indext'
import CardForm from '../../../../modules/donation/card-form'
import SavedCard from '../../../../modules/donation/saved-card'
import { SUBSCRIPTION_INTERVAL } from '../../../../helpers'
import { Methods } from '../../../../interfaces'

interface State {
  includeFees: boolean
  method: Methods
  subscription: any
  showRemoveCard: boolean
  saveCard: boolean
  isMonthly: boolean
  savedCard: any
  creditCard: {
    error_cardNumber: string
    error_cardExpiry: string
    error_cardCvc: string
    complete_cardNumber: boolean
    complete_cardExpiry: boolean
    complete_cardCvc: boolean
  }
}

const baseService = new BaseService()

// tslint:disable-next-line:class-name
class _CardEdit extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      includeFees: false,
      isMonthly: true,
      subscription: null,
      method: 'card',
      showRemoveCard: false,
      savedCard: null,
      saveCard: true,
      creditCard: {
        error_cardNumber: '',
        error_cardExpiry: '',
        error_cardCvc: '',
        complete_cardNumber: false,
        complete_cardExpiry: false,
        complete_cardCvc: false,
      },
    }
  }

  cardChange = (e: any, elType: 'cardNumber' | 'cardExpiry' | 'cardCvc') => {
    this.setState({
      creditCard: { ...this.state.creditCard, [`complete_${elType}`]: e.complete },
    })
    if (e.error) {
      this.setState({
        creditCard: { ...this.state.creditCard, [`error_${elType}`]: e.error.message },
      })
    } else {
      this.setState({
        creditCard: { ...this.state.creditCard, [`error_${elType}`]: '' },
      })
    }
  }

  getSources = async () => {
    const res = await baseService.getJSON('user/get-sources')
    const sub = await baseService.getJSON(`subscription/${this.props.match.params.id}`)
    this.setState({
      savedCard: res.sources[0] || {},
      subscription: { ...sub.data.subscription, amount: sub.data.subscription.amount / 100 },
    })
  }
  removeSavedCard = async () => {
    const res = await baseService.postJSON('donation/remove-saved-card', {
      customer: this.state.savedCard.customer,
      cardId: this.state.savedCard.id,
    })

    if (res.success) {
      this.setState({
        savedCard: {},
      })
    }
  }
  componentDidMount() {
    this.getSources()
  }

  saveCard = async () => {
    const { complete_cardNumber, complete_cardExpiry, complete_cardCvc } = this.state.creditCard
    if ((complete_cardNumber && complete_cardExpiry && complete_cardCvc) || !!this.state.savedCard.id) {
      const { token } = await this.props.stripe.createToken()
      await baseService.postJSON('user/update-card', { stripeToken: token })
      await this.getSources()
      // eslint-disable-next-line
      this.props.history.goBack()
    }
  }

  render() {
    const {
      method,
      creditCard: { error_cardNumber, error_cardExpiry, error_cardCvc },
      includeFees,
      showRemoveCard,
      savedCard,
      subscription,
      saveCard,
      isMonthly,
    } = this.state

    return savedCard ? (
      <>
        <main className="screen edit-card-page">
          <header className="header">
            <nav className="main-menu">
              <div className="home" onClick={() => this.props.history.goBack()}>
                <img src="/assets/images/arrow-back.svg" alt="home-icon" />
              </div>
              <img src="/assets/images/logo.png" alt="logo" />
            </nav>
          </header>

          <section className="sub-header">
            <p>{subscription.corporate}</p>
            <h1>Payment Method</h1>
            <div className="overlay" />
          </section>

          <section className="content">
            <div className="edit-card">
              <p className="amount">${subscription.amount}</p>
              <p>{SUBSCRIPTION_INTERVAL.find((i: any) => i.value === subscription.intervalCount)!.title}</p>
            </div>

            <PaymentMethods method={method} changeMethod={(e: Methods) => this.setState({ method: e })} />

            {!savedCard.id && (
              <div className="available-cards">
                <img src="/assets/images/credit-cards.png" alt="available-cards" />
              </div>
            )}

            {savedCard.id && method === 'card' ? (
              <SavedCard savedCard={savedCard} onShowRemoveCard={() => this.setState({ showRemoveCard: true })} />
            ) : (
              method === 'card' && (
                <CardForm
                  numberChange={e => this.cardChange(e, 'cardNumber')}
                  expiryChange={e => this.cardChange(e, 'cardExpiry')}
                  cvcChange={e => this.cardChange(e, 'cardCvc')}
                />
              )
            )}

            {!!error_cardNumber && <CardError error={error_cardNumber} />}
            {!!error_cardExpiry && <CardError error={error_cardExpiry} />}
            {!!error_cardCvc && <CardError error={error_cardCvc} />}

            <CheckBox
              checked={includeFees}
              style={{ marginTop: method === 'card' ? 0 : 25 }}
              onClick={() => this.setState({ includeFees: !includeFees })}
            >
              <p>Cover the processing fees</p>
            </CheckBox>

            {!savedCard.id && method === 'card' && (
              <CheckBox checked={saveCard} onClick={() => !isMonthly && this.setState({ saveCard: !saveCard })}>
                <p>Save for faster donations later</p>
              </CheckBox>
            )}

            <div className="corporate">
              <p>Corporate matching?</p>
              <input
                type="text"
                placeholder="Your Company"
                className="input"
                onChange={({ target: { value } }) => value}
              />
            </div>

            <div className="actions">
              <button onClick={() => this.saveCard()} className="save-card">
                {' '}
                Save{' '}
              </button>
            </div>
          </section>

          {showRemoveCard && (
            <RemoveCard
              onClose={() => this.setState({ showRemoveCard: false })}
              onRemove={async () => {
                await this.removeSavedCard()
                this.setState({ showRemoveCard: false })
              }}
            />
          )}
        </main>
      </>
    ) : (
      <p>Loading</p>
    )
  }
}

function CardError({ error }: { error: string }) {
  return (
    <p className="card-error">
      <img src="/assets/images/round-error.svg" alt="error" />
      {error}
    </p>
  )
}

const Injected = injectStripe(_CardEdit, { withRef: true })

export default class CardEdit extends React.Component<any> {
  render() {
    return (
      <Elements>
        <Injected {...this.props} />
      </Elements>
    )
  }
}

import * as React from 'react'
import './style.scss'
import Button from '../../../components/button'

interface Props {
  onRemove: () => void
  onClose: () => void
}

export default function RemoveCard(props: Props) {
  const { onRemove, onClose } = props
  return (
    <div className="remove-card-pop-up">
      <div className="bg" onClick={() => onClose()} />
      <div className="content">
        <span className="close" onClick={() => onClose()}>
          &#10799;
        </span>
        <h2>Remove Card</h2>
        <p>Your current saved credit card information will be removed.</p>
        <Button onClick={() => onRemove()} color="warning" label="Remove"/>
      </div>
    </div>
  )
}

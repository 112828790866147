import * as React from 'react'
import './style.scss'
import PageHeader from '../../../modules/common/page-header'
import NonprofitFooter from '../../../modules/nonprofit/footer'
import NonprofitContent from '../../../modules/nonprofit/content'
import Button from '../../../components/button'
import Input from '../../../components/input'
import IsMonthly from '../../../components/is-monthly'
import { useState } from 'react'
import CheckBox from '../../../components/checkbox'
import * as _ from 'lodash'
import DonationService from '../../../services/donation.service'
import { response } from 'express'
import Loader from '../../../components/loader'
const queryString = require('query-string')

const donationService = new DonationService()

const donationAmounts = [
  { label: '$500', value: 500 },
  { label: '$200', value: 200 },
  { label: '$100', value: 100 },
  { label: '$50', value: 50 },
  { label: '$25', value: 25 },
]

const donationMultiples = [1, 2, 4]

function NonProfitDonation(props: any) {
  const [anonymously, setAnonymously] = useState(false)
  const [loading, setLoading] = useState(true)
  const { defaultAmount, minimumAmount } = props.nonProfit
  React.useEffect(() => {
    if (props.match.params.id === _.get(props.nonProfit, 'organization.id')) {
      return undefined
    }
    
    const updateOrganization = async () => {
      const res = await donationService.getOrganization(props.match.params.id)
      if (res.success) {
        props.setNonProfit('organization', res.organization)
        props.setNonProfit('org_name', res.organization.name)
        setLoading(false)
      } else {
        window.location.replace(`/nonprofit/${props.match.params.id}`)
      }

      props.setNonProfit('amount', defaultAmount || minimumAmount || 5)
    }

    updateOrganization().catch( e => console.log(e))
  }, [props.match.params.id])

  if (loading) {
    return <Loader />
  }

  return (
    <article className="non-profit-donation">
      <PageHeader goBack title={props.nonProfit.org_name} subtitle="Donation" />
      <NonprofitContent>
        <p className="slogan">How much would you like to donate?</p>
        <div className={defaultAmount > 0 ? 'donation-multiples' : 'donation-amounts'}>
          {
            defaultAmount > 0 ? donationMultiples.map(m => (
              <Button color="primary" label={`$${defaultAmount * m}`} key={m} onClick={() => props.setNonProfit('amount', defaultAmount * m)} />
            ))
            :
            donationAmounts.map((amount, i) => (
              <Button color="primary" label={amount.label} key={i} onClick={() => props.setNonProfit('amount', amount.value)} />
            ))
          }
        </div>
        <div className="wrapper">
          <Input
            value={props.nonProfit.amount}
            placeholder={`$${minimumAmount || '5.00'} or more`}
            type="number"
            onChange={value => {
              if ((/^\d+(\.\d{1,2})?$/g.test(value) && Number(value) <= 1000) || value === '') {
                return props.setNonProfit('amount', Number(value))
              }
            }}
          />
          <IsMonthly
            onChange={() => props.setNonProfit('isMonthly', !props.nonProfit.isMonthly)}
            monthly={props.nonProfit.isMonthly}
          />
          <textarea cols={43} rows={5} placeholder="Notes for organization" />
          <CheckBox className="checkbox-anonymously" checked={anonymously} onClick={() => setAnonymously(!anonymously)}>
            <p className="anonymously">Donate anonymously</p>
          </CheckBox>
          <Button
            disabled={props.nonProfit.amount <= 0}
            color="primary"
            label="Continue"
            isLink={true}
            to="payment-info"
          />
        </div>
      </NonprofitContent>
      <NonprofitFooter />
    </article>
  )
}

export default NonProfitDonation

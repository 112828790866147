import Amplify  from 'aws-amplify'
import BaseService from '../services/base.service'

const baseService = new BaseService()

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  // API: {
  //   endpoints: [
  //     {
  //       name: 'donationsApi',
  //       endpoint: process.env.REACT_APP_API_ENDPOINT,
  //       custom_header: async () => ({ Authorization: await baseService.getAuthorizationToken() }),
  //     },
  //   ],
  // },
})

import { Auth } from 'aws-amplify'

const MAIN_API = process.env.REACT_APP_API_ENDPOINT

export interface Session {
  hours: string
  org_name: string
  org_id: string
  userId: string
  access_token: string
  app_link: string
}

export interface JsonResponse {
  success: boolean
  message?: string
  sources?: any
  email?: string
  isStripeError?: boolean
}

export default class BaseService {

  getAuthorizationToken = async () => {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  }

  setSession(session: Session): void {
    const { hours, org_name, userId, access_token, app_link, org_id } = session
    return window.localStorage.setItem(
      'session',
      JSON.stringify({
        hours,
        org_name,
        org_id,
        access_token,
        userId,
        app_link: `${app_link}://`,
      }),
    )
  }

  currentSession(): Session {
    const session = JSON.parse(window.localStorage.getItem('session') || '{}')
    return session ? { ...session, amount: Number(session.amount) } : null
  }

  setStorageByKey(key: string, value: any): any {
    return window.localStorage.setItem(key, JSON.stringify(value))
  }

  setAmount(amount: number): void {
    return window.localStorage.setItem('amount', JSON.stringify(amount))
  }

  getAmount(): number | null {
    const amount = window.localStorage.getItem('amount')
    return amount ? JSON.parse(amount) : null
  }

  async currentUser(): Promise<any> {
    return await Auth.currentUserInfo()
  }

  requestWithToken = () => {
    if (!!this.currentSession().access_token || !!JSON.parse(window.localStorage.getItem('access_token') as any)) {
      const token = this.currentSession().access_token || JSON.parse(window.localStorage.getItem('access_token') || '{}')
      return {
        'Content-Type': 'application/json',
        Authorization: token
      }
    } else {
      return { 'Content-Type': 'application/json' }
    }
  }

  async getJSON(api: string): Promise<any> {
    try {
      if (!!api && api.charAt(0) !== '/') {
        api = '/' + api
      }
      const req = await fetch(MAIN_API + api, {
        method: 'get',
        headers: this.requestWithToken(),
      } as any)
      const res = await req.json()
      return this.checkAuth(res)
    } catch (e) {
      console.error('', e)
    }
  }

  async postJSON(api: string, body: object): Promise<any> {
    try {
      if (!!api && api.charAt(0) !== '/') {
        api = '/' + api
      }
      const req: any = await fetch(MAIN_API + api, {
        method: 'post',
        headers: this.requestWithToken(),
        body: JSON.stringify(body),
      } as any)
      const res = await req.json()
      return this.checkAuth(res)
    } catch (e) {
      console.error('', e)
    }
  }

  async putJSON(api: string, body: object): Promise<any> {
    if (!!api && api.charAt(0) !== '/') {
      api = '/' + api
    }
    try {
      const req: any = await fetch(MAIN_API + api, {
        method: 'put',
        headers: this.requestWithToken(),
        body: JSON.stringify(body),
      } as any)
      const res = await req.json()
      return this.checkAuth(res)
    } catch (e) {
      console.error('', e)
    }
  }

  checkAuth = (res: any) => {
    if (!res.success && (res.status === 401 || res.statusCode === 401)) {
      return (window.location.href = '/unauthorized')
    }
    return res
  }
}

import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import PaymentInfo from './reducers/NonProfitDonation'
import NonProfitDonation from './reducers/NonProfitDonation'
import SignUp from './reducers/UserInfo'
import SignUpSecondStep from './reducers/UserInfo'
import SignUpThirdStep from './reducers/UserInfo'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
}

const reducers = combineReducers({
  PaymentInfo,
  NonProfitDonation,
  SignUp,
  SignUpSecondStep,
  SignUpThirdStep
})

// @ts-ignore 
const persistedReducer = persistReducer(persistConfig, reducers)
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

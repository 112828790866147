import React from 'react'
import Button from '../../../../components/button'
import './style.scss'
import DonationService from '../../../../services/donation.service'

const donationService = new DonationService()

export default function NonProfitThanks(props: any) {
  const { amount, includeFees, organization } = props.nonProfit
  const finalAmount = includeFees ? donationService.calcFees(amount) : amount
  const closeWindow = () => {
      window.close()
      // window.close does not always work
      if (!window.closed) {
          window.location.href = "https://civicchamps.com/";
      }
  }

  const doneButton = () => {
      if (organization && organization.website) {
          const link = (organization.website.indexOf('://') === -1) ? 'http://' + organization.website : organization.website;
          return (
              <a href={link} style={{textDecoration: 'none'}}>
                  <Button label="Done" color="secondary"/>
              </a>
          )
      }
      return <Button label="Done" onClick={closeWindow} color="secondary"/>
  }


  return (
    <article className="non-profit-thanks">
      <img className="logo" src="/assets/images/logo.png" alt="logo" />
      <h1>Thank You</h1>
      <h2>For your ${finalAmount} {props.nonProfit.isMonthly ? 'monthly' : ''} donation!</h2>
        { doneButton() }
      <img src="/assets/images/logo_white.png" alt="logo_white" className="logo_white" />
    </article>
  )
}

import * as React from 'react'
import './style.scss'

export default function CardError({ error }: { error: string }) {
  return (
    <p className="card-error">
      <img src="/assets/images/round-error.svg" alt="error" />
      {error}
    </p>
  )
}

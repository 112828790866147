import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../modules/common/page-header'
import NonprofitContent from '../../../../modules/nonprofit/content'
import NonprofitFooter from '../../../../modules/nonprofit/footer'
import Button from '../../../../components/button'
import Input from '../../../../components/input'
import { useState } from 'react'
import { Auth } from 'aws-amplify'
import BaseService from '../../../../services/base.service'
import Loader from '../../../../components/loader'
import {Link} from 'react-router-dom'
import _ from 'lodash'
const queryString = require('query-string')

const base = new BaseService()
export default function Login(props: any) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isError, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const q = queryString.parse(props.location.search)
  const token = _.get(q, 'token')
  const cognitoSub = _.get(q, 'cognito_sub')
  const campaignId = _.get(q, 'campaign_id')

  const signIn = async () => {
    setLoading(true)
    try {
      await Auth.signIn(username, password)
      const currentUser = await Auth.currentUserInfo()
      const res = await base.postJSON('/donation/auth', {
        cognitoSub: currentUser.attributes.sub,
      })
      if (res.access_token) {
        base.setStorageByKey('access_token', res.access_token)
        setLoading(false)
        return props.history.push('donation')
      }
      setLoading(false)
    } catch (error) {
      console.log('error ', error)
      setLoading(false)
      setError(error.message)
    }
  }

  React.useEffect(() => {
    const authorize = async () => {
      if (token && cognitoSub) {
        const validateResponse = await base.postJSON('/donation/auth/validate', {
          cognitoSub,
          token
        })
        if (validateResponse.success) {
          const autoResponse = await base.postJSON('/donation/auth', {
            cognitoSub
          })
          if (autoResponse.access_token) {
            base.setStorageByKey('access_token', autoResponse.access_token)
            return props.history.push(`donation?campaign_id=${campaignId}`)
          }
        }
      }
    }

    authorize()
  }, [token, cognitoSub])

  return (
    <article className="non-profit-login">
      <PageHeader goBack title="Habitat for Humanity" subtitle="Donation" />
      <NonprofitContent>
        <p className="slogan">Login with Civic Champs</p>
        <Input
          type="text"
          placeholder="Email or Phone"
          value={username}
          onChange={e => {
            setUsername(e)
            setError('')
          }}
          enterPress={signIn}
          errorMessage={isError}
          error={!!isError}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => {
            setError('')
            setPassword(e)
          }}
          enterPress={signIn}
        />
        <Button color="primary" label="Login" onClick={signIn} />
        <Link className="sign-up" to="sign-up/">
          Create an account
        </Link>
      </NonprofitContent>
      {loading && <Loader/>}
      <NonprofitFooter />
    </article>
  )
}

import * as React from 'react'
import './style.scss'

interface Props {
  style?: any
  onClick: () => void
  checked: boolean
  children: any
  className?: string
}

export default function CheckBox(props: Props) {
  return (
    <div
      className={`checkbox ${props.className ? props.className : ''}`}
      style={props.style ? props.style : {}}
      onClick={() => props.onClick && props.onClick()}
    >
      <label className={`switch ${props.checked ? 'active' : ''}`}>
        <img src="/assets/images/check.svg" className="checked" alt="check" />
        <span className="slider" />
        <img alt="mark" src="/assets/images/x-mark.svg" className="x-mark" />
      </label>
      {props.children}
    </div>
  )
}

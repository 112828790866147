import * as React from 'react'
import './style.scss'

interface Props {
  onClose?: () => void
  error?: string
}

export default function Error(props: Props) {
  return (
    <div className="error-pop">
      <div className="bg" onClick={() => (props.onClose ? props.onClose() : null)} />
      <div className="content">
        <span className="close" onClick={() => (props.onClose ? props.onClose() : null)}>
          &#10799;
        </span>
        <h2>Oops</h2>
        <p>Something didn't work.</p>
        { props.error &&
          <p>
            Error: <b>{props.error}</b>
          </p>
        }
        <p>Please try again.</p>
        <button onClick={() => (props.onClose ? props.onClose() : null)}>Ok</button>
      </div>
    </div>
  )
}

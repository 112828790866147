import React from 'react'
import './style.scss'

export default function Loader() {
  return (
    <div className="loader">
      <img src="/assets/images/logo.svg" alt="logo-blue" className="pulse"/>
    </div>
  )
}

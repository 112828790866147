import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../modules/common/page-header'
import NonprofitContent from '../../../../modules/nonprofit/content'
import NonprofitFooter from '../../../../modules/nonprofit/footer'
import Button from '../../../../components/button'
import Input from '../../../../components/input'
import { useState } from 'react'
import { Auth } from 'aws-amplify'
import BaseService from '../../../../services/base.service'
import Loader from '../../../../components/loader'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {parseOpportunityId} from "../../../../helpers";
const queryString = require('query-string')

const base = new BaseService()

const initialAmount = 4

export default function CampaignLanding(props: any) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const q = queryString.parse(props.location.search)
  const token = _.get(q, 'token')
  const cognitoSub = _.get(q, 'cognito_sub')
  const campaignId = _.get(q, 'campaign_id')
  const activityId = _.get(q, 'activity_id')
  const opportunityId = _.get(q, 'opportunity_id')
  const hour = _.get(q, 'hours')
  const [amount, setAmount] = useState(initialAmount)
  const [title, setTitle] = useState('The Challenge')
  const [image, setImage] = useState('')
  const [text, setText] = useState('All of the hours you donate to support us are incredible. However, we also need to pay for things surrounding our mission. So we are challenging our volunteers to go an extra step and donate $1 per 1 hour they volunteer. If you can help us out, please click the Donate button below to learn more.')

  const createDonationClickedEvent = async () => {
    await base.postJSON(`/campaigns/${campaignId}/event`, {
      opportunityId,
      type: 'donation_clicked',
    })
  }

  React.useEffect(() => {
    props.clearNonProfit()
    const authorize = async () => {
      if (!!token && !!cognitoSub) {
        base.setStorageByKey('access_token', token)
      } else {
        setError('Forbidden: token is required')
        return
      }
      props.setNonProfit('defaultAmount', initialAmount)
      props.setNonProfit('minimumAmount', initialAmount)
      setAmount(initialAmount)
      if (!!campaignId) {
        const campaign = await base.getJSON(`/campaigns/${campaignId}`)
        let hoursVolunteered = 1
        if (!!hour) {
          hoursVolunteered = parseFloat(hour)
        } else if (!!activityId) {
          const activity = await base.getJSON(`/activities/${activityId}`)
          hoursVolunteered = activity.hoursVolunteered
        }
        // rounding to the closest $0.50
        const defaultAmount = Math.round((2 * hoursVolunteered * campaign.timeMatchingAmountInCents / campaign.timeMatchingHour) / 100) / 2
        props.setNonProfit('defaultAmount', defaultAmount)
        props.setNonProfit('activityId', activityId)
        props.setNonProfit('opportunityId', parseOpportunityId(opportunityId))
        setAmount(defaultAmount)

        props.setNonProfit('minimumAmount', campaign.minimumAmountInCents / 100)
        props.setNonProfit('campaignId', campaignId)

        const donateTitle = _.get(campaign, 'donatePrompt.title')
        if (!!donateTitle) {
          setTitle(donateTitle)
        }
        const donateText = _.get(campaign, 'donatePrompt.firstParagraph')
        if (!!donateText) {
          setText(donateText)
        }
        const donateImage = _.get(campaign, 'donatePrompt.image')
        if (!!donateImage) {
          setImage(donateImage)
        }
      }
      setLoading(false)
    }
    authorize()
  }, [token, cognitoSub, campaignId, activityId])

  if (!!error) {
    return <div>{error}</div>
  }

  if (loading) {
    return <Loader />
  }

  return (
    <article className="non-profit-login">
      <PageHeader goBack title={title} />
      <NonprofitContent>
        <p className="content" 
         dangerouslySetInnerHTML={{
          __html: text
        }}
        />
        {!!image && <img className="image" src={image}/>}
        <Link onClick={createDonationClickedEvent} className="donate" to={'donation'}>
          Donate {`$${amount.toFixed(2)}`}
        </Link>
      </NonprofitContent>
      <NonprofitFooter />
    </article>
  )
}

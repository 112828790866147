import * as React from 'react'
import './style.scss'
import PageHeader from '../../../../modules/common/page-header'
import NonprofitContent from '../../../../modules/nonprofit/content'
import NonprofitFooter from '../../../../modules/nonprofit/footer'
import Button from '../../../../components/button'
import { JsonResponse } from '../../../../services/base.service'
import DonationService from '../../../../services/donation.service'
import { useEffect, useState } from 'react'
import { Elements, injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements'
import Error from '../../../../components/error'
import Loader from '../../../../components/loader'

const donationService = new DonationService()

const handleChargeResponse = (
  {
    res,
    setError,
    onSuccess
  } : {
    res?: JsonResponse,
    setError: (data: string) => void,
    onSuccess?: () => void
  }
) => {
  const { isStripeError, message, success } = res || {}
  if (success) {
    return onSuccess && onSuccess()
  } else {
    // we do not want to blindly display errors unless they came directly from stripe
    return setError(isStripeError && message || 'Charge could not be processed')
  }
}

function _DonationSummary(props: any) {
  const [isError, setError] = useState('')
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  let {
    amount,
    method,
    saveCard,
    corporate,
    user,
    stripe_token,
    isMonthly,
    plaid,
    savedCard,
    includeFees,
    organization,
    campaignId,
    opportunityId
  } = props.nonProfit
  amount = includeFees ? donationService.calcFees(amount) : amount
  const net = donationService.calcNet(amount)

  useEffect(() => {
    const startPaymentProcess = async () => {
      if (['google', 'apple'].includes(method)) {
        const paymentRequest = props.stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: `Donation to ${props.match.params.id}`,
            amount: amount * 100,
          },
        })

        paymentRequest.on('token', async ({ complete, token, ...data }: any) => {
          const res: any = await donationService.charge({
            amount,
            saveCard,
            stripe_token: token.id,
            isMonthly,
            organizationStripeAccountId: organization.id,
            fees: amount - net,
            feesPaidByDonor: !!includeFees,
            campaignId,
            opportunityId
          })
          handleChargeResponse({
            res,
            onSuccess: () => {
              complete('success')
              return props.history.push('thanks')
            },
            setError
          })
        })

        const canMakePayment = await paymentRequest.canMakePayment()

        if (canMakePayment) {
          setPaymentRequest(paymentRequest)
        } else {
          return setError('Need saved payment method on browser')
        }
      }
    }
    startPaymentProcess()
  }, [!!paymentRequest])

  const donate = async () => {
    setIsLoading(true)
    let res: JsonResponse
    if (!!savedCard.id && method === savedCard.object) {
      res = await donationService.chargeSavedCard({
        amount,
        saveCard,
        stripe_token,
        isMonthly,
        organizationStripeAccountId: organization.id,
        feesPaidByDonor: !!includeFees,
        fees: amount - net,
        campaignId,
        opportunityId
      })
    } else {
      res = await donationService.charge({
        amount,
        saveCard,
        stripe_token,
        isMonthly,
        organizationStripeAccountId: organization.id,
        feesPaidByDonor: !!includeFees,
        fees: amount - net,
        campaignId,
        opportunityId
      })
    }
    setIsLoading(false)
    handleChargeResponse({
      res,
      onSuccess: () => props.history.push('thanks'),
      setError
    })
  }
  return (
    <article className="non-profit-donation-summary">
      <PageHeader goBack title={organization.name} subtitle="Donation" />
      <NonprofitContent>
        <div className="text">
          <p className="slogan">Today you are donating </p>
          <p>${includeFees ? donationService.calcFees(props.nonProfit.amount) : props.nonProfit.amount.toFixed(2)}</p>
          {props.nonProfit.isMonthly && <p>Monthly</p>}
          <p>to</p>
          <p>{props.nonProfit.org_name}</p>
        </div>
        {['google', 'apple'].includes(method) && !!paymentRequest ? (
          <PaymentRequestButtonElement
            paymentRequest={paymentRequest as any}
            className="PaymentRequestButton"
            style={{
              paymentRequestButton: {
                theme: 'light',
                type: 'donate',
                height: '64px',
              },
            }}
          />
        ) : (
          <Button label="Donate Now" color="primary" onClick={donate} />
        )}
      </NonprofitContent>
      <NonprofitFooter />
      {!!isError && <Error error={isError} onClose={() => {
        setError('')
        window.history.back()
      }} />}
      {isLoading && <Loader/>}
    </article>
  )
}

const Injected = injectStripe(_DonationSummary)

export default function DonationSummary(props: any) {
  return (
    <Elements>
      <Injected {...props} />
    </Elements>
  )
}

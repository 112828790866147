import * as React from 'react'
import './style.scss'

interface Props {
  scrollable?: boolean
  children: any
}

export default function NonprofitContent(props: Props) {
  const { scrollable } = props
  return <section className={`module nonprofit-content ${scrollable && 'scrollable'}`}>{props.children}</section>
}

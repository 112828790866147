import * as React from 'react'
import { Subscription } from '../../../../services/subscription.service'
import { Link } from 'react-router-dom'
import BaseService from '../../../../services/base.service'
import './style.scss'
import { useEffect, useState } from 'react'
import { SUBSCRIPTION_INTERVAL } from '../../../../helpers'

const baseService = new BaseService()

interface State {
  amount: number
  intervalCount: any
  paused?: boolean
  error?: boolean
  data: {
    subscription: Subscription
    firstDonation: number
    nextDonation: number
    source: string
  }
}

export default function EditSubscription(props: any) {
  const [subscription, setSubscription] = useState<State | null>(null)

  useEffect(() => {
    const getSubscription = async () => {
      const res = await baseService.getJSON(`subscription/${props.match.params.id}`)

      setSubscription({
        data: res.data,
        amount: res.data.subscription.amount / 100,
        intervalCount: res.data.subscription.intervalCount,
      })
    }

    getSubscription()
    // eslint-disable-next-line
  }, [])

  if (subscription === null) {
    return null
  }
  const updateSubscription = async () => {
    if (Number(subscription.amount) < 1) {
      return setSubscription({ ...subscription, error: true })
    }
    const data = {
      amount: Number(subscription.amount) * 100,
      intervalCount: Number(subscription.intervalCount),
      paused: Number(subscription.intervalCount) === 0,
    }
    await baseService.putJSON(`subscription/${props.match.params.id}`, data)
    window.history.back()
  }

  return (
    <main className="screen subscription-edit-page">
      <header className="header">
        <nav className="main-menu">
          <span className="home" onClick={() => window.history.back()}>
            <img src="/assets/images/arrow-back.svg" alt="home-icon" />
          </span>
          <img src="/assets/images/logo.png" alt="logo" />
        </nav>
      </header>

      <section className="sub-header">
        <h1>Monthly Donations</h1>
        <p>Editing Subscription</p>
      </section>

      <section className="content">
        <h2>{subscription.data.subscription.corporate}</h2>
        <div className="amount">
          <p>monthly donation amount</p>
          <input
            type="number"
            min={1}
            max={1000}
            className={subscription.error ? 'error' : ''}
            value={subscription.amount || ''}
            onChange={({ target: { value } }) => {
              if ((/^[1-9][0-9]*$/g.test(value) && Number(value) <= 1000) || value === '') {
                setSubscription({ ...subscription, amount: Number(value), error: false })
              }
            }}
          />
        </div>
        <div className="status">
          <p>How often to donate</p>
          <select
            name="interval"
            value={subscription.intervalCount}
            onChange={({ target: { value } }) => setSubscription({ ...subscription, intervalCount: value })}
          >
            {SUBSCRIPTION_INTERVAL.map((sub, index) => (
              <option key={index} value={sub.value}>
                {sub.title}
              </option>
            ))}
          </select>
        </div>
        <div className="payment_source">
          <div className="title">
            <p>Payment Source</p>
          </div>
          <div className="source">
            **** **** **** {subscription.data.source}{' '}
            <Link to={'card-edit'}>
              <img src="/assets/images/square-edit.svg" alt="edit" />
            </Link>
          </div>
        </div>
        <button type="button" onClick={async () => await updateSubscription()}>
          Update
        </button>
      </section>
    </main>
  )
}

import { Subscription } from '../services/subscription.service'
import { useEffect, useState } from 'react'

export const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|".+")@((\[[0-9]{1,3}\.0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneNumberRegex = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/
export const passwordREGEX = /[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*)$)/

export const validate = {
  email: (email: string) => emailRegex.test(email),
  password: (pwd: string) => passwordREGEX.test(pwd) && pwd.length >= 8,
  phoneNumber: (phone: string) =>  phoneNumberRegex.test(phone.replace(/\s+/g, ''))
}

export const SUBSCRIPTION_INTERVAL = [
  { value: 1, title: 'Monthly' },
  { value: 2, title: 'Every 2 months' },
  { value: 3, title: 'Every 3 months' },
  { value: 6, title: 'Every 6 months' },
  { value: 1, title2: 'Once a year' },
  { value: 0, title: 'Pause' },
]

export const getInterval = (subscription: Subscription) => {
  if (subscription.paused) {
    return 'Paused'
  } else {
    if (subscription.interval === 'month') {
      if (subscription.intervalCount === 1) {
        return 'Monthly'
      } else if (subscription.intervalCount > 1) {
        return `${subscription.intervalCount} months`
      }
    } else if (subscription.interval === 'year') {
      if (subscription.intervalCount === 1) {
        return 'Yearly'
      } else if (subscription.intervalCount > 1) {
        return `${subscription.intervalCount} years`
      }
    } else {
      if (subscription.intervalCount === 1) {
        return 'Daily'
      } else if (subscription.intervalCount > 1) {
        return `${subscription.intervalCount} days`
      }
    }
  }
}

export function LoadStripe({ children, uniqueId, script, loader = 'Loading...' }: any) {
  const [stripeLoaded, setStripeLoaded] = useState<any | null>({})

  useEffect(() => {
    const loadScript = (src: string, uniqueId: string) =>
      new Promise((resolve, reject) => {
        const scriptElement = document.getElementById(uniqueId)

        if (!scriptElement) {
          const script = document.createElement('script')
          script.src = src
          script.id = uniqueId

          const handleLoadScriptSuccess = () => resolve({ successful: true })
          const handleLoadScriptFail = (event: any) => reject({ error: event })

          script.addEventListener('load', handleLoadScriptSuccess, {
            once: true,
          })
          script.addEventListener('error', handleLoadScriptFail, { once: true })
          document.head.appendChild(script)
        } else {
          resolve({ successful: true })
        }
      })
    const fetchData = async () => {
      const result = await loadScript(script, uniqueId)
      setStripeLoaded(result)
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  return stripeLoaded.successful ? children : loader
}

export const decodeOccurrenceFromMobileClient = (occurrence: number | string): { opportunityId: number, date: string } => {
  const stringifiedOccurrence = String(occurrence)
  const date = `${stringifiedOccurrence.substring(1, 5)}-${stringifiedOccurrence.substring(5, 7)}-${stringifiedOccurrence.substring(7, 9)}`
  const opportunityId = Number(stringifiedOccurrence.substring(9, stringifiedOccurrence.length))
  return { date, opportunityId }
}

export const decodeOccurrence = (base64string: string | number): { date?: string; opportunityId: number } =>  {
  const stringAsNumber = Number(base64string)
  if (isNaN(stringAsNumber)) {
    // this is then a classic encodedOccurrence, used by 'admin' and 'scheduler' repeatedly
    const bufferObj = Buffer.from(String(base64string), 'base64')
    const jsonString = bufferObj.toString('ascii')
    return JSON.parse(jsonString)
  } else {
    if (stringAsNumber > 0) {
      // this is just a straight id from the opportunity table - shouldn't happen - just a fallback
      return { opportunityId: stringAsNumber }
    } else {
      // this was an encodedId that we created for the client, which is expecting a number, not a string, so we cannot use the regular encoding
      return decodeOccurrenceFromMobileClient(stringAsNumber)
    }
  }
}

export const parseOpportunityId = (ambiguousId: string | number): number =>  {
  const { opportunityId } = decodeOccurrence(ambiguousId)
  return opportunityId
}

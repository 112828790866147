import { Methods } from '../../interfaces'

const CHANGE_NONPROFIT = 'CHANGE_AMOUNT'
const CLEAR_NONPROFIT = 'CLEAR_NONPROFIT'

interface Organization {
  id?: number
  connectId: string
  name: string
  status: string
}

interface State {
  nonProfit: {
    amount: number
    isMonthly: boolean
    saveCard: boolean
    savedCard: any
    includeFees: boolean
    org_name: string
    firstName: string
    lastName: string
    address: string
    city: string
    state: string
    zip: string
    stripe_token: string
    method: Methods
    corporate: string
    user: { userId: string; email: string }
    plaid: { public_token: string; account_id: string }
    organization: Organization
    campaignId?: number
    minimumAmount?: number
    defaultAmount?: number
    activityId?: number
  }
}

const initialState: State = {
  nonProfit: {
    amount: 0,
    isMonthly: false,
    savedCard: {},
    saveCard: false,
    includeFees: false,
    org_name: 'Loading...',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    method: 'card',
    stripe_token: '',
    corporate: '',
    user: { userId: '', email: '' },
    plaid: { public_token: '', account_id: '' },
    organization: { connectId: '', name: '', status: '' },
  },
}

export type NonProfitKeys =
  | 'amount'
  | 'isMonthly'
  | 'saveCard'
  | 'savedCard'
  | 'includeFees'
  | 'org_name'
  | 'firstName'
  | 'lastName'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'plaid'
  | 'method'
  | 'stripe_token'
  | 'user'
  | 'corporate'
  | 'organization'
  | 'campaignId'
  | 'minimumAmount'
  | 'defaultAmount'
  | 'activityId'
  | 'opportunityId'


export function setNonProfit(key: NonProfitKeys, e: boolean | string | number | object) {
  return { type: CHANGE_NONPROFIT, val: e, key: key }
}

export function clearNonProfit() {
  return { type: CLEAR_NONPROFIT }
}

export default function reducer(state = initialState, action: any): State {
  switch (action.type) {
    case CHANGE_NONPROFIT:
      return { nonProfit: { ...state.nonProfit, [action.key]: action.val } }

    case CLEAR_NONPROFIT:
      return { nonProfit: { ...initialState.nonProfit } }

    default:
      return state
  }
}

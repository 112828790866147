import * as React from 'react'
import './style.scss'
import BaseService from '../../../services/base.service'
import moment from 'moment'

const baseService = new BaseService()

interface State {
  report: any[]
}

export default class YearReportPopup extends React.Component<any, State> {
  getSubscriptions = async () => {
    const res = await baseService.getJSON(
      `donation/report/${this.props.corporate}/subscription/${this.props.id}/${this.props.year}`,
    )
    this.setState<any>({
      report: res.report,
    })
  }

  getTitle = (report: any) => {
    report = report[Object.keys(report)[0]]
    if (Object.keys(report).length) {
      if (report.action === 'customer.subscription.deleted') {
        return 'Paused'
      } else {
        if (report.interval === 'month') {
          if (report.interval_count === 1) {
            return `$${(report.amount / 100).toFixed(2)} monthly`
          } else if (report.interval_count > 1) {
            return `$${(report.amount / 100).toFixed(2)} ${report.interval_count} months`
          }
        } else if (report.interval === 'year') {
          if (report.interval_count === 1) {
            return `$${(report.amount / 100).toFixed(2)} yearly`
          } else if (report.interval_count > 1) {
            return `$${(report.amount / 100).toFixed(2)} ${report.interval_count} years`
          }
        } else if (report.interval === 'day') {
          if (report.interval_count === 1) {
            return `$${(report.amount / 100).toFixed(2)} daily`
          } else if (report.interval_count > 1) {
            return `$${(report.amount / 100).toFixed(2)} ${report.interval_count} days`
          }
        } else {
          return `$${(report.amount / 100).toFixed(2)}`
        }
      }
    } else {
      return 'empty'
    }
  }

  async componentDidMount() {
    await this.getSubscriptions()
  }

  render() {
    if (!this.state || !this.state.report) {
      return null
    }
    return (
      <>
        <div className="overlay" onClick={() => this.props.onClose && this.props.onClose()} />
        <article className="year-report-popup">
          <span className="close" onClick={() => this.props.onClose && this.props.onClose()}>
            &#10799;
          </span>
          <h2 className="year">{this.props.year}</h2>

          <section className="donations">
            {this.state.report.map((item, index) => (
              <p className={this.getTitle(item)} key={index}>
                <span className="date">{moment(Object.keys(item)[0]).format('MM/DD/YY')}</span> -{' '}
                {this.getTitle(item) === 'empty' ? '' : this.getTitle(item)}
              </p>
            ))}
          </section>

          <section className="total">${this.props.totalDonations}</section>

          <section className="footer">
            <button>Send Receipt</button>
          </section>
        </article>
      </>
    )
  }
}
